
@import url('https://fonts.cdnfonts.com/css/brittany-signature');
@import url('https://fonts.cdnfonts.com/css/lato-light');

.background {
  background-image: url("images/ibiza.jpeg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

}



.beige {

    background-color:#F7F6F3;
    padding-bottom: 2rem;
}

.contact {
  text-align: center;
  font-family: 'Brittany Signature',
  sans-serif;
  color: #BB9983;
  padding-top: 6rem;
  padding-bottom: 6rem;
  font-size: 50px;

}

@media screen and (max-width: 900px) {
  #Navv {
    width: 190px;
  }
}

.logo {
    position: absolute;
    max-width: 15rem;

}

.logo2 {
  position: absolute;
  max-width: 15rem;

}

.contact-btn {
  margin-top: 10rem;
  border-radius: 0rem;
  border-width: 0.1rem;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Lato Light', sans-serif;
}


.caption {
  text-align: center;
    font-family: 'Brittany Signature',
        sans-serif;
        color: #FFFFFF;           
        font-size: 50px;
           
      
}


.caption-2 {
text-align: center;
  font-family: 'Brittany Signature',
    sans-serif;
  color: #FFFFFF;
  font-size: 30px;



}



.col-text-1 {
margin-top: 0rem;
  margin-bottom: 2rem;
/*
  margin-left: 6rem;
  margin-right: 10rem;
   */
  letter-spacing: 1.5px;
  font-size: 14px;
  line-height: 1.8rem;
  font-family: 'Lato Light', sans-serif;

}

.col-text-1:hover {
  font-weight: 900;
}

.col-text {
  margin-top: 2rem;
    margin-bottom: 2rem;
  /*
  margin-left: 6rem;
  margin-bottom: 0rem;
  margin-right: 8rem;
  */
  letter-spacing: 1.5px;
  line-height: 2rem;
  font-size: 14px;
  font-family: 'Lato Light', sans-serif;

}

.col-text:hover {
  font-weight: 900;
}

.col-text-2 {
    margin-top: 2rem;
    
    /*
      margin-left: 6rem;
      margin-bottom: 0rem;
      margin-right: 8rem;
      */
      letter-spacing: 1.5px;
      line-height: 2rem;
      font-size: 14px;
      font-family: 'Lato Light', sans-serif;
}
.col-text-2:hover {
  font-weight: 900;
}
.col-text-3 {
    margin-top: 2rem;
      margin-bottom: 2rem;
  /*

    margin-left: 6rem;
      margin-bottom: 4rem;
      margin-right: 8rem;
     */
      letter-spacing: 1.5px;
      line-height: 1.8rem;
      font-size: 15px;
      font-family: 'Lato Light', sans-serif;
}

.col-text-3:hover {
  font-weight: 900;
}

.col-text-4 {
  margin-top: 0rem;
  margin-bottom: 2rem;
  /*
  margin-left: 6rem;
  margin-right: 10rem;
   */


  letter-spacing: 1.5px;
  font-size: 14px;
  line-height: 1.8rem;
  font-family: 'Lato Light', sans-serif;

}

.col-text-5 {
  /*padding-top: 3rem;
  padding-bottom: 2rem;
  
*/

    letter-spacing: 1.5px;
    font-size: 14px;
    line-height: 1.8rem;
    text-align: justify;
    font-family: 'Lato Light', sans-serif;
  
  }

  .col-text-5:hover {
    font-weight: 900;
  }

.col-text-4:hover {
  font-weight: 900;
}
form {
margin-top: 2rem;
max-width: 50%;
margin-left: 25%; 
margin-bottom: 8rem;
font-family: 'Lato Light', sans-serif;
font-size: 20px;
color: black;
}



.contact-2 {
  text-align: center;
  font-family: 'Brittany Signature',
    sans-serif;
  color: #BB9983;
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-size: 50px;

}

.icons {

  font-size: 30px;
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 2rem;
   letter-spacing: 2rem;

}

.icons a:hover {
  color: orange;
}

.image {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
 

}

.website {
  text-align: center;
  font-size: 12px;
  margin-top: 2rem;
  letter-spacing: 0.12rem;
}
.website:hover {
  font-weight: 500;
}

.natasha {
  margin-top: 6rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.02rem;
}





/*
App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/